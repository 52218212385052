/** Ionic CSS Variables **/
:root {
    --card-border-radius: 10px;
    /** primary **/
  --ion-color-primary: #395099;
  --ion-color-primary-rgb: 51, 122, 183;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #283868;
  --ion-color-primary-tint: #5975cb;

    --ion-color-dark-theme: #000000;
    --ion-color-dark-light: #161616;
    // #47B5F7
    --ion-color-dark-theme-rgb: 0, 0, 0;
    --ion-color-dark-theme-contrast: #ffffff;
    --ion-color-dark-theme-contrast-rgb: 255, 255, 255;
    --ion-color-dark-theme-shade: #303030;
    --ion-color-dark-theme-tint: #2d2d2d;

    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #4fdb89;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #000000;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #86888f;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: darken(var(--ion-color-medium), 5%);
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f4;
    --ion-color-light-background: #dee2e6;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-base: #69bb7b;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #5ca56c;
    --ion-color-tint: #78c288;

    --ion-color-facebook: #3c5a99;
    --ion-color-facebook-rgb: 60, 90, 153;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #2f436e;
    --ion-color-facebook-tint: #7792cc;

    --ion-color-google: #dd4b39;
    --ion-color-google-rgb: 221, 75, 57;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255, 255, 255;
    --ion-color-google-shade: #a33627;
    --ion-color-google-tint: #ec6f5f;

    --ion-color-instagram: #c32aa3;
    --ion-color-instagram-rgb: 195, 42, 163;
    --ion-color-instagram-contrast: #ffffff;
    --ion-color-instagram-contrast-rgb: 255, 255, 255;
    --ion-color-instagram-shade: #9c2783;
    --ion-color-instagram-tint: #e757c8;

    --ion-color-twitter: #00acee;
    --ion-color-twitter-rgb: 0, 172, 238;
    --ion-color-twitter-contrast: #ffffff;
    --ion-color-twitter-contrast-rgb: 255, 255, 255;
    --ion-color-twitter-shade: #1a8dc2;
    --ion-color-twitter-tint: #5cb9f1;

    --ion-color-youtube: #c4302b;
    --ion-color-youtube-rgb: 196, 48, 43;
    --ion-color-youtube-contrast: #ffffff;
    --ion-color-youtube-contrast-rgb: 255, 255, 255;
    --ion-color-youtube-shade: #a33627;
    --ion-color-youtube-tint: #a12b25;
  }

  .ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook) !important;
    --ion-color-base-rgb: var(--ion-color-facebook-rgb) !important;
    --ion-color-contrast: var(--ion-color-facebook-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-facebook-shade) !important;
    --ion-color-tint: var(--ion-color-facebook-tint) !important;
  }

  .ion-color-google {
    --ion-color-base: var(--ion-color-google) !important;
    --ion-color-base-rgb: var(--ion-color-google-rgb) !important;
    --ion-color-contrast: var(--ion-color-google-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-google-shade) !important;
    --ion-color-tint: var(--ion-color-google-tint) !important;
  }

  .ion-color-instagram {
    --ion-color-base: var(--ion-color-instagram) !important;
    --ion-color-base-rgb: var(--ion-color-instagram-rgb) !important;
    --ion-color-contrast: var(--ion-color-instagram-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-instagram-shade) !important;
    --ion-color-tint: var(--ion-color-instagram-tint) !important;
  }

  .ion-color-twitter {
    --ion-color-base: var(--ion-color-twitter) !important;
    --ion-color-base-rgb: var(--ion-color-twitter-rgb) !important;
    --ion-color-contrast: var(--ion-color-twitter-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-twitter-shade) !important;
    --ion-color-tint: var(--ion-color-twitter-tint) !important;
  }

  .ion-color-youtube {
    --ion-color-base: var(--ion-color-youtube) !important;
    --ion-color-base-rgb: var(--ion-color-youtube-rgb) !important;
    --ion-color-contrast: var(--ion-color-youtube-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-youtube-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-youtube-shade) !important;
    --ion-color-tint: var(--ion-color-youtube-tint) !important;
  }

  // @media (prefers-color-scheme: dark) {
  //   :root {
  //     /** dark **/
  //     --ion-color-dark: #f4f5f8;
  //     --ion-color-dark-rgb: 244, 244, 244;
  //     --ion-color-dark-contrast: #000000;
  //     --ion-color-dark-contrast-rgb: 0, 0, 0;
  //     --ion-color-dark-shade: #d7d8da;
  //     --ion-color-dark-tint: #f5f6f9;

  //     /** medium **/
  //     --ion-color-medium: #989aa2;
  //     --ion-color-medium-rgb: 152, 154, 162;
  //     --ion-color-medium-contrast: #ffffff;
  //     --ion-color-medium-contrast-rgb: 255, 255, 255;
  //     --ion-color-medium-shade: #86888f;
  //     --ion-color-medium-tint: #a2a4ab;

  //     /** light **/
  //     --ion-color-light: #222428;
  //     --ion-color-light-rgb: 34, 34, 34;
  //     --ion-color-light-contrast: #ffffff;
  //     --ion-color-light-contrast-rgb: 255, 255, 255;
  //     --ion-color-light-shade: #1e2023;
  //     --ion-color-light-tint: #383a3e;
  //   }

  // }
