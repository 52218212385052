.btn {
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.375rem;
  height: 3rem;
  line-height: 1;
  background-color: var(--ion-color-dark);
  background-color: transparent;
  background-image: -webkit-linear-gradient(
    left,
    var(--ion-color-dark-theme) 0%,
    var(--ion-color-dark-theme-tint) 100%
  );
  background-image: linear-gradient(
    to right,
    var(--ion-color-dark-theme) 0%,
    var(--ion-color-dark-theme-tint) 100%
  );
  background-size: 101%;
  opacity: 0.99999;
  -webkit-transition-property: box-shadow, opacity, background-color,
    -webkit-transform;
  transition-property: box-shadow, opacity, background-color, -webkit-transform;
  transition-property: box-shadow, opacity, transform, background-color;
  transition-property: box-shadow, opacity, transform, background-color,
    -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  font-weight: 500;
  color: white;
  outline: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.5rem;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: none;
  max-width: 500px;
  &:hover {
    color: var(--ion-color-light);
  }
  &--spotify {
    border: 2px solid #1db954;
    color: #1db954;
    background: transparent;
    box-shadow: none;
    &:hover {
      background-color: #1db954;
      color: inherit;
    }
  }
  &--shadow {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08),
      0px 4px 7px -2px rgba(0, 0, 0, 0.07),
      0px 5px 11px -2px rgba(0, 0, 0, 0.12);
    &:hover {
      background-image: -webkit-linear-gradient(
        left,
        var(--ion-color-dark-theme-shade) 0%,
        var(--ion-color-dark-theme) 100%
      );
      background-image: linear-gradient(
        to right,
        var(--ion-color-dark-theme-shade) 0%,
        var(--ion-color-dark-theme) 100%
      );
    }
  }

  &--light {
    background: var(--ion-color-light);
    color: var(--ion-color-dark);
    font-size: 1.1rem;
    border-radius: 10px;
    &:hover {
      color: var(--ion-color-dark-theme-shade);
    }
  }
  &--border-light {
    background: transparent;
    color: var(--ion-color-light);
    border: 1px solid var(--ion-color-light);
    font-size: 1.1rem;
    border-radius: 10px;
    &:hover {
      color: var(--ion-color-light-shade);
    }
  }
}

.link {
  font-weight: 700;
  color: var(--ion-color-dark-theme);
  & > fa-icon{
    color: var(--ion-color-dark-theme);
    transform: translate3d(0,0,0);
    transition: transform 0.2s ease-in-out;
  }
  &:hover,
  &:active {
    text-decoration: none;
    color: var(--ion-color-dark-theme);
    & > fa-icon{
      transform: translate3d(50%, 0, 0);
    }
  }
  &-underline {
    text-decoration: underline !important;
  }
}

.btn.btn-primary {
  // background: -webkit-linear-gradient(
  //   left,
  //   var(--ion-color-primary) 0%,
  //   var(--ion-color-primary-tint) 100%
  // );
  // background: linear-gradient(
  //   to right,
  //   var(--ion-color-primary) 0%,
  //   var(--ion-color-primary-tint) 100%
  // );
  color: #ffffff;
  background: var(--ion-color-dark-theme);
  text-transform: uppercase;
    padding: 1em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    max-width: 250px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    line-height: normal;
    z-index: 1;
    transition: all 0.3s ease-out;
    border-radius: .25em;
    height: auto;
    font-weight: 700;
    &:before {
      content: '';
      width: 300px;
      height: 300px;
      border-radius: 100%;
      background: var(--ion-color-dark-theme-tint);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.5s ease-out;
      z-index: -1;
    }
    &:hover {
      &:before {
        transform: translate(-50%, -50%) scale(1);
      }
    }

}

.bg-dark {
  .btn.btn-primary {
    color: var(--ion-color-dark) !important;
    background: -webkit-linear-gradient(
      left,
      var(--ion-color-dark-contrast) 0%,
      var(--ion-color-light) 100%
    );
    background: linear-gradient(
      to right,
      var(--ion-color-dark-contrast) 0%,
      var(--ion-color-light) 100%
    );
    &:before {
      background: var(--ion-color-light-shade);
    }
  }
}

.btn-outline-primary {
  background: transparent;
  color: var(--ion-color-dark-theme);
  border: 3px solid var(--ion-color-dark-theme);
  &:hover {
    background-color: var(--ion-color-dark-theme);
    border-color: var(--ion-color-dark-theme);
  }
}

a{
  text-decoration: none !important;
}

.mat-flat-button.mat-primary:not([disabled]), .mat-raised-button.mat-primary:not([disabled]){
  background-color: var(--ion-color-dark-theme) !important;
}


.btn--map {
  position: fixed;
  z-index: 3;
  padding: 1rem 1.5rem;
  height: 65px;
  font-size: 1.25rem;
  text-transform: uppercase;

  right: 5%;
  left: 5%;
  max-width: 12em;
  bottom: 0;
  margin: 0 auto;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover {
    color: var(--ion-color-light);
  }

  &--close {
    right: 2%;
    left: auto;
    top: 90px;
    font-size: 1.75rem;
    border-radius: 5px;
    height: 3rem;
  }

  @media(max-width: 768px) {
    padding: 1.25rem;
    height: 50px;
    font-size: 1rem;

    &--close {
      width: 2em;
      height: 2em;
      font-size: 1.5rem;
    }
  }

}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  min-width: unset !important;
  &.mat-button-thin {
    span {
      font-weight: 100 !important;
    }
    &.font-weight-bold {
      span {
        font-weight: 400 !important;
      }
    }
  }
}
